@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Mukta+Vaani');
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop
html,
body {
    height: 100%;
}

html {
    @include fluid-type($min_width,
    $max_width,
    $min_font,
    $max_font);
}

p,
ol,
ul {
    font-size: 1rem;
    font-family: 'Mukta Vaani', sans-serif;
}

a {
    font-size: 1rem;
    font-family: 'Mukta Vaani', sans-serif;
    color: #564B3D;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Mukta Vaani', sans-serif;
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Mukta Vaani', sans-serif;
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2 *$min_font);
    font-family: 'Mukta Vaani', sans-serif;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

// bootstrap row reset
.row {
    margin-left: 0px;
    margin-right: 0px;
}

nav {
    z-index: 1001;
}

.navbar-brand {
    float: none;
    height: 50px;
    padding: 0px;
    font-size: 18px;
    line-height: 20px;
    img {
        max-width: 150px;
        @media (max-width: 388px) {
            max-width: 200px;
        }
    }
}

nav.navbar {
    z-index: 1000;
    border-bottom: 4px solid #2dbaff;
    border-radius: none;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 15px;
            display: flex;
            align-items: center;
            color: #333;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: #333;
                outline: 0 !important;
            }
            &:hover {
                background: #2dbaff;
                border-radius: 5px;
                color: #202020;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: #000;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
    text-align: center;
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    @media (max-width: 767px) {
        margin: 20px 15px 8px 0px;
    }
}

.logo {
    max-width: 225px;
    margin-top: 5px;
}

// .navbar-toggle {
//     position: relative;
//     float: right;
//     padding: 10px 10px;
//     margin-top: 30px;
//     margin-right: 15px;
//     margin-bottom: 8px;
//     background-color: transparent;
//     background-image: none;
//     border: 1px solid transparent;
//     border-radius: 4px;
//     @media (max-width: 388px) {
//         margin-top: 14px;
//     }
// }
@media (min-width: 768px) {
    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: 0px;
    }
}

.modal-content {
    color: #B19F87;
    padding: 15px;
    h2 {
        text-align: center;
    }
}

.banner {
    margin-top: 144px;
    img {
        border-radius: 5px;
    }
}

.panel-top {
    margin-left: 5%;
    top: 175px;
    position: absolute;
    // margin-right: auto;
    height: auto;
    @media (min-width: 991px) {
        width: 400px;
    }
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    background-color: #B19F87;
    h1 {
        text-align: center;
    }
    @media (max-width: 991px) {
        display: none;
    }
}

.panel-top-mobile {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: #B19F87;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    h1 {
        padding: 10px;
        text-align: center;
    }
}

#signUpForm label {
    vertical-align: top;
    margin: 0 !important;
    padding: 0 !important;
    height: 30px !important;
    line-height: 30px !important;
    float: none !important;
    text-align: right !important;
    font-family: "Open Sans", Calibri, sans-serif !important;
    font-size: 14px !important;
    display: inline-block !important;
    font-weight: 700 !important;
    margin-bottom: 8px !important;
    width: 22% relative !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    color: #F8F8F8 !important;
}

.sec-panel {
    margin-top: 25px;
    background-color: white;
    border-radius: 5px;
    color: #564B3D;
    padding: 15px;
    img {
        margin-left: auto;
        margin-right: auto;
    }
}

.sec-panel-m {
    @media (min-width: 992px) {
        display: none;
    }
    margin-top: 25px;
    background-color: white;
    border-radius: 5px;
    color: #564B3D;
    padding: 15px;
    img {
        margin-left: auto;
        margin-right: auto;
    }
}

.browse {
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    &-button {
        display: block;
        width: 33%;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        border-radius: 5px;
        padding: 12px;
        font-size: 16px;
        font-weight: 700px;
        &:hover {
            text-decoration: none;
            color: white;
            background-color: #564B3D;
        }
    }
}

.join {
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    &-button {
        display: block;
        width: 33%;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        border-radius: 5px;
        padding: 12px;
        font-size: 16px;
        font-weight: 700px;
        &:hover {
            text-decoration: none;
            color: white;
            background-color: #564B3D;
        }
    }
}

.modal-content {
    background-color: #B19F87;
    color: #f8f8f8;
}

.top-pad {
    color: #564B3D;
    margin-top: 25px;
    border-radius: 5px;
    padding: 15px;
}

// footer {
//     margin-top: 10px;
//     color: #564B3D;
//     background: white;
//     padding-top: 10px;
// }
footer {
    padding: 50px 0px 20px;
    background: white;
    color: #564B3D;
    font-size: 15px;
    a {
        color: #564B3D;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten(#564B3D, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: #564B3D;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}